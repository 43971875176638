import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import DaySattaResult from './js/daySattaResult';
import FooterButton from './js/footerButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import  App  from './App';
const RootComponent = () => {

  return (
    <div className='main-div'>
        <div className='container-fluid adjust'>
          {/* <DaySattaResult /> */}
          <App/>
          <FooterButton/>
        </div>
    </div>
  );
};

ReactDOM.render(
  <Router>
    <RootComponent />
  </Router>,
  document.getElementById('root')
);
